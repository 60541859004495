import React from "react";
import "./CarListItem.css";
import CarModal from "../CarModal";
import { observer } from "mobx-react";
import CustomSwitch from "../../../../components/CustomSwitch";
import CustomButton from "../../../../components/CustomButton";

const getAdmissionDateToDisplay = (admissionDate) =>
	admissionDate ? admissionDate.replace("00:00:00", "").trim() : "";

const CarListItem = observer((props) => {
	const handleActivation = () => {
		props.onUpdateCar({
			...props.car,
			isActive: !props.car.isActive,
		});
	};

	const { car } = props;
	return (
		<div className="Car">
			<div className="Car-header">
				<h4>
					{car.manufacturer} {car.model}&nbsp;
				</h4>
				<CustomSwitch
					checked={car.isActive}
					onChange={handleActivation}
					sx={{ marginLeft: "auto", marginTop: 0, marginBottom: 0 }}
				/>
			</div>
			<p>Kennzeichen: {car.licensePlate}&nbsp;</p>
			<p>
				Zulassungsdatum: {getAdmissionDateToDisplay(car.admissionDate)}&nbsp;
			</p>
			<p>Fahrg.Nr.: {car.chassisNo}&nbsp;</p>
			<CarModal
				title={"Fahrzeug bearbeiten"}
				initialValues={car}
				onSubmit={props.onUpdateCar}
				trigger={(setOpen) => (
					<CustomButton
						size={"small"}
						sx={{ marginTop: 0, marginBottom: 0, float: "right" }}
						onClick={setOpen}
					>
						bearbeiten
					</CustomButton>
				)}
			/>
		</div>
	);
});

export default CarListItem;
