import React from "react";
import "./PositionList.css";

//import components
import InvoicePosition from "../InvoicePosition/InvoicePosition";

class PositionList extends React.Component {
	constructor() {
		super();

		this.state = {};
	}

	render() {
		const props = this.props;

		return (
			<div className="PositionList">
				<div className="position-list-wrapper">
					{props.positions.map((position, index) => {
						return (
							<InvoicePosition
								position={position}
								key={index}
								pos={index + 1}
								onUpdate={props.onUpdate}
								onRemovePosition={props.onRemovePosition}
								onAddPosition={props.onAddPosition}
							/>
						);
					})}
				</div>
			</div>
		);
	}
}

export default PositionList;
