import React from "react";
import { FormControlLabel, Switch } from "@mui/material";

// eslint-disable-next-line react/display-name
const CustomSwitch = React.forwardRef(
	({ checked, onChange, label, labelPlacement, sx, ...rest }, ref) => {
		return (
			<FormControlLabel
				sx={sx}
				ref={ref}
				control={<Switch checked={checked} onChange={onChange} {...rest} />}
				label={label}
				labelPlacement={labelPlacement}
			/>
		);
	},
);

export default CustomSwitch;
