import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {
	getInvoiceDate,
	getSalutationToDisplay,
	numToDisplay,
	roundTwoDec,
} from "./utils";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const createDoc = (invoiceData, carData, customerData) => {
	const hasDiscount = invoiceData.positions.find(
		(pos) => pos.discount.toString() !== "0",
	);

	const totalOfPos = roundTwoDec(
		invoiceData.positions.reduce((prev, current) => prev + current.total, 0),
	);

	let taxesOnPos = totalOfPos * (invoiceData.VAT / 100);

	let taxes = invoiceData.totalNetto * (invoiceData.VAT / 100);

	let totalBrutto = (invoiceData.totalNetto * (100 + invoiceData.VAT)) / 100;

	const invoiceDate = getInvoiceDate(invoiceData.invoiceDate);

	const wage1 = invoiceData.wage1;
	const wage2 = invoiceData.wage2;

	const wage = {
		amount1: Number(wage1.amount) !== 0 ? numToDisplay(wage1.amount) : " ",
		price1:
			Number(wage1.pricePerUnit) !== 0
				? `${numToDisplay(wage1.pricePerUnit)} €`
				: " ",
		total1:
			Number(wage1.amount * wage1.pricePerUnit) !== 0
				? `${numToDisplay(wage1.pricePerUnit * wage1.amount)} €`
				: " ",

		amount2: Number(wage2.amount) !== 0 ? numToDisplay(wage2.amount) : " ",
		price2:
			Number(wage2.pricePerUnit) !== 0
				? `${numToDisplay(wage2.pricePerUnit)} €`
				: " ",
		total2:
			Number(wage2.amount * wage2.pricePerUnit) !== 0
				? `${numToDisplay(wage2.pricePerUnit * wage2.amount)} €`
				: " ",
	};

	return {
		pageSize: "A4",
		pageOrientation: "portrait",
		pageMargins: [40, 300, 30, 60],

		content: [
			{
				stack: [
					{ text: `${getSalutationToDisplay(customerData.salutation)}` },
					{ text: `${customerData.name}` },
					{ text: `${customerData.street}` },

					{ text: `${customerData.zip} ${customerData.town}` },
				],
				style: "customerData",
			},

			{
				stack: [
					{
						columns: [
							{ text: `Kunden_Nr.: `, bold: true },
							{ text: `${invoiceData.customerNo}`, alignment: "right" },
						],
					},

					{
						columns: [
							{ text: `Datum: `, bold: true },
							{ text: `${invoiceDate}`, alignment: "right" },
						],
					},
				],
				style: "invoiceDate",
			},

			{
				text: `Rechnungs-Nr.:     ${invoiceData.invoiceNo}`,
				bold: true,
				margin: [0, 0, 0, 3],
			},
			{
				columns: [
					{
						width: 150,
						stack: [
							{ text: "Fahrzeug", bold: true },
							{
								text: `${carData.manufacturer || ""} ${carData.model || ""}`,
								margin: [0, 0, 0, 3],
							},
							{ text: "Annahmetag", bold: true },
							{
								text: `${invoiceData.receptionDay || ""}`,
								margin: [0, 0, 0, 20],
							},
						],
					},

					{
						width: 130,
						stack: [
							{ text: "Amtl Kennz", bold: true },
							{ text: ` ${carData.licensePlate || ""}`, margin: [0, 0, 0, 3] },
							{ text: "KM-Stand", bold: true },
							{ text: ` ${invoiceData.kmStatus || ""}`, margin: [0, 0, 0, 20] },
						],
					},

					{
						width: 150,
						stack: [
							{ text: "Zulassungsdatum", bold: true },
							{ text: ` ${carData.admissionDate || ""}`, margin: [0, 0, 0, 3] },
							{ text: "Fahrg.-Nr.", bold: true },
							{ text: ` ${carData.chassisNo || ""}`, margin: [0, 0, 0, 20] },
						],
					},

					{
						width: 100,
						stack: [
							{ text: "Typ-Schl", bold: true },
							{ text: ` ${carData.typeKey || ""}`, margin: [0, 0, 0, 20] },
						],
					},
				],
			},

			{
				layout: "lightHorizontalLines",
				table: {
					// headers are automatically repeated if the table spans over multiple pages
					// you can declare how many rows should be treated as headers
					headerRows: 1,
					widths: [200, 60, 80, 50, 70],

					body: [
						[
							{ text: "Artikelbeschreibung", bold: true },
							{
								text: "Menge",
								bold: true,
								alignment: "right",
							},
							{
								text: `Einzelpreis`,
								bold: true,
								alignment: "right",
							},
							{
								text: `${hasDiscount ? "Rabatt(%)" : ""}`,
								bold: true,
								alignment: "right",
							},
							{ text: "Endpreis", bold: true, alignment: "right" },
						],
						...invoiceData.positions.map((position) => {
							return [
								{ text: `${position.description}` },
								{
									text: `${numToDisplay(position.amount)}`,
									alignment: "right",
								},
								{
									text: `${numToDisplay(position.price)} €`,
									alignment: "right",
								},
								{
									text: `${
										position.discount.toString() !== "0"
											? position.discount
											: ""
									}`,
									alignment: "right",
								},
								{
									text: `${numToDisplay(position.total)} €`,
									alignment: "right",
								},
							];
						}),
					],
				},
			},
			{
				canvas: [{ type: "line", x1: 0, y1: 0, x2: 525, y2: 0, lineWidth: 2 }],
				margin: [0, 0, 0, 5],
			},

			{
				stack: [
					{
						stack: [
							{
								columns: [
									{ text: `${wage1.name}`, width: 200 },
									{ text: wage.amount1, width: 75, alignment: "right" },
									{ text: wage.price1, width: 95, alignment: "right" },
									{ text: wage.total1, alignment: "right" },
								],
							},

							{
								columns: [
									{ text: `${wage2.name}`, width: 200 },
									{ text: wage.amount2, width: 75, alignment: "right" },
									{ text: wage.price2, width: 95, alignment: "right" },
									{ text: wage.total2, alignment: "right" },
								],
							},
						],
					},

					{
						columns: [
							{
								width: 200,
								stack: [
									{
										columns: [
											{ text: `Ersatzteilbetrag: `, bold: true },
											{
												text: `${numToDisplay(totalOfPos)} €`,
												alignment: "right",
											},
										],
									},

									{
										columns: [
											{ text: `MwSt ${invoiceData.VAT}%: `, bold: true },
											{
												text: `${numToDisplay(taxesOnPos)} €`,
												alignment: "right",
											},
										],
									},
								],
							},

							{
								stack: [
									{
										columns: [
											{ text: `Betrag Netto:`, bold: true },
											{
												text: `${numToDisplay(invoiceData.totalNetto)} €`,
												alignment: "right",
											},
										],
									},

									{
										columns: [
											{ text: `MwSt ${invoiceData.VAT}%:`, bold: true },
											{ text: `${numToDisplay(taxes)} €`, alignment: "right" },
										],
									},

									{
										columns: [
											{ text: `Betrag Brutto:`, bold: true },
											{
												text: `${numToDisplay(totalBrutto)} €`,
												alignment: "right",
											},
										],
									},
								],
								margin: [150, 0, 0, 0],
							},
						],
						margin: [0, 30, 0, 0],
					},
				],
			},

			{
				canvas: [{ type: "line", x1: 0, y1: 0, x2: 525, y2: 0, lineWidth: 1 }],
				margin: [0, 15, 0, 10],
			},

			{
				text: "Rechnung zahlbar innerhalb von 8 Tagen",
				bold: true,
				fontSize: 13,
			},
		],

		styles: {
			customerData: {
				margin: [30, -150, 0, 65],
			},
			invoiceDate: {
				margin: [320, 0, 60, 30],
			},
		},
	};
};

export default createDoc;
