"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true,
});
exports.updateInvoice =
	exports.updateCustomer =
	exports.updateCar =
	exports.deleteInvoice =
	exports.deleteCustomer =
	exports.deleteCar =
	exports.createInvoice =
	exports.createCustomer =
	exports.createCar =
		void 0;

/* eslint-disable */
// this is an auto generated file. This will be overwritten
const deleteInvoice =
	/* GraphQL */
	`
		mutation DeleteInvoice(
			$input: DeleteInvoiceInput!
			$condition: ModelInvoiceConditionInput
		) {
			deleteInvoice(input: $input, condition: $condition) {
				id
				invoiceNo
				customerNo
				carID
				invoiceDate
				wage1 {
					name
					amount
					pricePerUnit
				}
				wage2 {
					name
					amount
					pricePerUnit
				}
				totalNetto
				positions {
					description
					amount
					price
					discount
					total
				}
				receptionDay
				kmStatus
				tuev
				exhaustInvestigation
				status
				VAT
				createdAt
				updatedAt
			}
		}
	`;
exports.deleteInvoice = deleteInvoice;
const createCustomer =
	/* GraphQL */
	`
		mutation CreateCustomer(
			$input: CreateCustomerInput!
			$condition: ModelCustomerConditionInput
		) {
			createCustomer(input: $input, condition: $condition) {
				id
				customerNo
				salutation
				name
				street
				town
				zip
				isActive
				invoices {
					nextToken
				}
				cars {
					nextToken
				}
				createdAt
				updatedAt
			}
		}
	`;
exports.createCustomer = createCustomer;
const updateCustomer =
	/* GraphQL */
	`
		mutation UpdateCustomer(
			$input: UpdateCustomerInput!
			$condition: ModelCustomerConditionInput
		) {
			updateCustomer(input: $input, condition: $condition) {
				id
				customerNo
				salutation
				name
				street
				town
				zip
				isActive
			}
		}
	`;
exports.updateCustomer = updateCustomer;
const deleteCustomer =
	/* GraphQL */
	`
		mutation DeleteCustomer(
			$input: DeleteCustomerInput!
			$condition: ModelCustomerConditionInput
		) {
			deleteCustomer(input: $input, condition: $condition) {
				id
				customerNo
				salutation
				name
				street
				town
				zip
				isActive
				invoices {
					nextToken
				}
				cars {
					nextToken
				}
				createdAt
				updatedAt
			}
		}
	`;
exports.deleteCustomer = deleteCustomer;
const createInvoice =
	/* GraphQL */
	`
		mutation CreateInvoice(
			$input: CreateInvoiceInput!
			$condition: ModelInvoiceConditionInput
		) {
			createInvoice(input: $input, condition: $condition) {
				id
				invoiceNo
				customerNo
				carID
				invoiceDate
				wage1 {
					name
					amount
					pricePerUnit
				}
				wage2 {
					name
					amount
					pricePerUnit
				}
				totalNetto
				positions {
					description
					amount
					price
					discount
					total
				}
				receptionDay
				kmStatus
				tuev
				exhaustInvestigation
				status
				VAT
				createdAt
				updatedAt
			}
		}
	`;
exports.createInvoice = createInvoice;
const updateInvoice =
	/* GraphQL */
	`
		mutation UpdateInvoice(
			$input: UpdateInvoiceInput!
			$condition: ModelInvoiceConditionInput
		) {
			updateInvoice(input: $input, condition: $condition) {
				id
				invoiceNo
				customerNo
				carID
				invoiceDate
				wage1 {
					name
					amount
					pricePerUnit
				}
				wage2 {
					name
					amount
					pricePerUnit
				}
				totalNetto
				positions {
					description
					amount
					price
					discount
					total
				}
				receptionDay
				kmStatus
				tuev
				exhaustInvestigation
				status
				VAT
				createdAt
				updatedAt
			}
		}
	`;
exports.updateInvoice = updateInvoice;
const createCar =
	/* GraphQL */
	`
		mutation CreateCar(
			$input: CreateCarInput!
			$condition: ModelCarConditionInput
		) {
			createCar(input: $input, condition: $condition) {
				id
				customerNo
				manufacturer
				licensePlate
				admissionDate
				chassisNo
				model
				typeKey
				capacity
				kW
				isActive
				createdAt
				updatedAt
			}
		}
	`;
exports.createCar = createCar;
const updateCar =
	/* GraphQL */
	`
		mutation UpdateCar(
			$input: UpdateCarInput!
			$condition: ModelCarConditionInput
		) {
			updateCar(input: $input, condition: $condition) {
				id
				customerNo
				manufacturer
				licensePlate
				admissionDate
				chassisNo
				model
				typeKey
				capacity
				kW
				isActive
				createdAt
				updatedAt
			}
		}
	`;
exports.updateCar = updateCar;
const deleteCar =
	/* GraphQL */
	`
		mutation DeleteCar(
			$input: DeleteCarInput!
			$condition: ModelCarConditionInput
		) {
			deleteCar(input: $input, condition: $condition) {
				id
				customerNo
				manufacturer
				licensePlate
				admissionDate
				chassisNo
				model
				typeKey
				capacity
				kW
				isActive
				createdAt
				updatedAt
			}
		}
	`;
exports.deleteCar = deleteCar;
