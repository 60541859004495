import React from "react";
import "./EditableInvoiceModal.css";

import PositionList from "./PositionList/PositionList";
import WageSection from "./Wage/Wage";
import InvoiceCar from "./InvoiceCar/InvoiceCar";
import {
	numToDisplay,
	getProperties,
	roundTwoDec,
} from "../../../../utils/utils";

import createPdf from "../../../../utils/pdfDoc";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { asNumber, calculateTotalWage } from "../../../../utils/utils";
import { observer } from "mobx-react";
import CustomButton from "../../../../components/CustomButton";
import CustomSwitch from "../../../../components/CustomSwitch";
import PrintIcon from "@mui/icons-material/Print";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const createEmptyPosition = () => ({
	description: "",
	amount: "0,00",
	price: "0,00",
	discount: "0",
	total: 0,
});

class EditableInvoice extends React.Component {
	constructor(props) {
		super(props);

		const invoice = this.props.invoice
			? { ...this.props.invoice }
			: { positions: [createEmptyPosition()] };
		const car = this.props.car ? { ...this.props.car } : {};

		const useVAT = invoice.VAT !== 0;

		const wage1 = invoice.wage1;
		const wage2 = invoice.wage2;

		this.state = {
			useVAT: useVAT,

			invoice: { ...invoice, positions: [...invoice.positions] },
			car: { ...car },
			wage: {
				wage1: wage1 ? wage1.name : "",
				amount1: wage1 ? wage1.amount : 0,
				price1: wage1 ? wage1.pricePerUnit : 0,
				wage2: wage2 ? wage2.name : "",
				amount2: wage2 ? wage2.amount : 0,
				price2: wage2 ? wage2.pricePerUnit : 0,
			},
			status: invoice.status,
		};

		this.addPosition = this.addPosition.bind(this);
		this.removePosition = this.removePosition.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.updatePosition = this.updatePosition.bind(this);
		this.updateWage = this.updateWage.bind(this);
		this.calculateTotal = this.calculateTotal.bind(this);
		this.updateCarProperties = this.updateCarProperties.bind(this);
		this.printInvoice = this.printInvoice.bind(this);
		this.handleVatToggle = this.handleVatToggle.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	resetState() {
		const invoice = this.props.invoice
			? { ...this.props.invoice }
			: { positions: [createEmptyPosition()] };
		const car = this.props.car ? { ...this.props.car } : {};

		const useVAT = invoice.VAT !== 0;

		const wage1 = invoice.wage1;
		const wage2 = invoice.wage2;

		this.setState({
			useVAT: useVAT,

			invoice: { ...invoice, positions: [...invoice.positions] },
			car: { ...car },
			wage: {
				wage1: wage1 ? wage1.name : "",
				amount1: wage1 ? wage1.amount : 0,
				price1: wage1 ? wage1.pricePerUnit : 0,
				wage2: wage2 ? wage2.name : "",
				amount2: wage2 ? wage2.amount : 0,
				price2: wage2 ? wage2.pricePerUnit : 0,
			},
			status: invoice.status,
		});
	}

	closeModal() {
		this.resetState();
		this.props.close();
	}

	calculateTotal() {
		const totalWage = calculateTotalWage(this.state.wage);
		let totalPriceOfPositions = 0;
		if (this.state.invoice.positions.length > 0)
			this.state.invoice.positions.map(
				(pos) => (totalPriceOfPositions += pos.total ? Number(pos.total) : 0),
			);

		const totalNetto = totalPriceOfPositions + totalWage;
		const invoice = this.state.invoice;

		invoice.totalNetto = totalNetto;

		this.setState({ invoice });
	}

	addPosition(index) {
		const invoice = this.state.invoice;
		const positions = invoice.positions;

		positions.splice(index, 0, createEmptyPosition());
		this.setState({ invoice }, () => {
			document.getElementById(`invoice-position-${index + 1}`).scrollIntoView();
		});
	}

	removePosition(index) {
		const invoice = this.state.invoice;
		const positions = invoice.positions;

		if (positions.length > 1) {
			positions.splice(index, 1);
			this.setState({ invoice }, () => {
				this.calculateTotal();
			});
		}
	}

	updatePosition(position, index) {
		let discount = asNumber(position.discount);
		let amount = asNumber(position.amount);
		let price = asNumber(position.price);

		discount = !discount ? 1 : (100 - discount) / 100;
		amount = !amount ? 0 : amount;
		price = !price ? 0 : price;

		const total = amount * price * discount;
		position.total = roundTwoDec(total);

		const positions = this.state.invoice.positions;
		positions[index] = position;
		this.setState({ invoice: { ...this.state.invoice, positions } }, () => {
			this.calculateTotal();
		});
	}

	updateWage(wage) {
		this.setState({ wage: wage }, () => {
			this.calculateTotal();
		});
	}

	updateCarProperties(
		id,
		customerNo,
		manufacturer,
		licensePlate,
		admissionDate,
		chassisNo,
		model,
		typeKey,
		receptionDay,
		kmStatus,
		tuev,
	) {
		const car = {
			id: id,
			customerNo: customerNo,
			manufacturer: manufacturer,
			licensePlate: licensePlate,
			chassisNo: chassisNo,
			admissionDate: admissionDate,
			model: model,
			typeKey: typeKey,
		};
		const invoice = this.state.invoice;
		invoice.receptionDay = receptionDay;
		invoice.kmStatus = kmStatus;
		invoice.tuev = tuev;
		this.setState({ car, invoice });
	}

	async onSubmit() {
		const car = this.state.car;

		let isEmpty = true;
		for (const value of Object.values(car)) {
			isEmpty = !value;
			if (!isEmpty) break;
		}

		const customer = this.props.selectedCustomer;
		const wage = this.state.wage;

		let totalNetto = this.state.invoice.totalNetto;
		totalNetto = roundTwoDec(totalNetto);

		const invoice = {
			customerNo: customer.customerNo,
			id: this.state.invoice.id,
			wage1: {
				name: wage.wage1,
				amount: wage.amount1,
				pricePerUnit: wage.price1,
			},
			wage2: {
				name: wage.wage2,
				amount: wage.amount2,
				pricePerUnit: wage.price2,
			},
			positions: this.state.invoice.positions,

			totalNetto: totalNetto,
			receptionDay: this.state.invoice.receptionDay,
			kmStatus: this.state.invoice.kmStatus,
			tuev: this.state.invoice.tuev,

			VAT: this.state.useVAT ? Number(this.props.properties.config.VAT) : 0,
		};

		let newCar;
		if (!isEmpty) {
			if (car.id) {
				newCar = await this.props.onUpdateCar(car);
				invoice.carID = newCar.id;
			} else {
				newCar = await this.props.onCreateCar({
					...car,
					customerNo: customer.customerNo,
				});
				invoice.carID = newCar.id;
			}
		}
		const updatedInvoice = await this.props.onSubmit(invoice);

		return [updatedInvoice, newCar];
	}

	async printInvoice() {
		let [invoice, car] = await this.onSubmit();

		car = car ? car : {};

		const customer = this.props.selectedCustomer;

		const docDefinition = createPdf(invoice, car, customer);
		pdfMake.createPdf(docDefinition).print();

		const newInvoiceStatus = "PRINTED";

		this.props.onUpdateInvoiceStatus(invoice, newInvoiceStatus);
	}

	handleVatToggle() {
		this.setState({ useVAT: !this.state.useVAT });
	}

	render() {
		const properties = getProperties();

		const useVAT = this.state.useVAT;
		const vat = useVAT ? Number(properties.config.VAT) : 0;

		let totalNetto = this.state.invoice.totalNetto
			? this.state.invoice.totalNetto
			: 0;
		let taxes = (totalNetto * vat) / 100;
		let totalBrutto = (totalNetto * (100 + vat)) / 100;

		totalNetto = numToDisplay(totalNetto);
		taxes = numToDisplay(taxes);
		totalBrutto = numToDisplay(totalBrutto);

		return (
			<div className="NewInvoice">
				<h2>Rechnung erstellen</h2>
				<div className="CarProperties">
					<InvoiceCar
						selectedCustomer={this.props.selectedCustomer}
						selectedCars={
							this.props.selectedCars ? this.props.selectedCars : []
						}
						onUpdateCarProperties={this.updateCarProperties}
						car={this.props.car}
						invoice={this.state.invoice}
					/>
				</div>
				<div className="InvoiceData">
					<h3 id="Rechnungspositionen">Rechnungspositionen</h3>
					<div className="InvoiceProperties">
						<h4>Pos.</h4>
						<h4>Beschreibung</h4>
						<h4 id="Menge">Menge</h4>
						<h4 id="Einzelpreis">Einzelpreis</h4>
						<h4 id="Rabatt">Rabatt(%)</h4>
						<h4 id="Gesamtpreis">Gesamt</h4>
					</div>
					<div className="Positions">
						<PositionList
							positions={this.state.invoice.positions}
							onUpdate={this.updatePosition}
							onRemovePosition={this.removePosition}
							onAddPosition={this.addPosition}
						/>
					</div>
				</div>
				<div className="SalaryInvoiceTotalWrapper">
					<div>
						<WageSection onUpdate={this.updateWage} wage={this.state.wage} />
					</div>
					<div className="editable-invoice-total-wrapper">
						<h3 id="total">Gesamtpreis</h3>
						<div className="editable-invoice-total">
							<div>
								<p>Betrag Netto:</p>
								<p>MwSt. {vat}%:</p>
								<p>Betrag Brutto:</p>
							</div>
							<div>
								<p>{totalNetto} €</p>
								<p>{taxes} €</p>
								<p>{totalBrutto} €</p>
							</div>
						</div>
					</div>
				</div>

				<div className="editable-invoice-bottom">
					<CustomButton
						onClick={() => {
							this.props.close();
						}}
					>
						abbrechen
					</CustomButton>
					<CustomButton
						startIcon={<PrintIcon />}
						onClick={() => {
							this.printInvoice();
							this.props.close();
						}}
					>
						drucken
					</CustomButton>
					<CustomButton
						onClick={() => {
							this.onSubmit();
							this.props.close();
						}}
					>
						speichern
					</CustomButton>
					<CustomSwitch
						checked={!useVAT}
						onChange={this.handleVatToggle}
						label={"ohne MwSt:"}
						labelPlacement="start"
					/>
				</div>
			</div>
		);
	}
}

export default observer(EditableInvoice);
