import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App/App";
import * as serviceWorker from "./serviceWorker";
import { Amplify } from "aws-amplify";
import { awsConfig } from "./aws-exports";
import { GraphqlApi } from "./graphql/api";
import RootStore from "./mobX/RootStore";
import { RootStoreContext } from "./mobX/RootStore";

import "@aws-amplify/ui-react/styles.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { createTheme, ThemeProvider } from "@mui/material";
import { I18n } from "aws-amplify";
import { translations } from "@aws-amplify/ui-react";

I18n.putVocabularies(translations);
I18n.setLanguage("de");

Amplify.configure(awsConfig());

const transportLayer = new GraphqlApi();
const rootStore = new RootStore(transportLayer);

const theme = createTheme({
	palette: {
		primary: {
			main: "#143C91",
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				sizeSmall: { transform: "scale(0.8)" },
			},
		},
	},
});

ReactDOM.render(
	<RootStoreContext.Provider value={rootStore}>
		<ThemeProvider theme={theme}>
			<App />
		</ThemeProvider>
	</RootStoreContext.Provider>,
	document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
