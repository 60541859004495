import React from "react";
import "./InvoicePosition.css";
import { numToDisplay } from "../../../../../utils/utils";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { IconButton } from "@mui/material";

class InvoicePosition extends React.Component {
	constructor(props) {
		super(props);

		this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
		this.handleAmountChange = this.handleAmountChange.bind(this);
		this.handleDiscountChange = this.handleDiscountChange.bind(this);
		this.handlePriceChange = this.handlePriceChange.bind(this);
		this.removePosition = this.removePosition.bind(this);
		this.addPosition = this.addPosition.bind(this);

		this.handleOnBlur = this.handleOnBlur.bind(this);
	}

	removePosition() {
		this.props.onRemovePosition(this.props.pos - 1);
	}

	addPosition() {
		const index = this.props.pos;

		this.props.onAddPosition(index);
	}

	handleDescriptionChange(event) {
		const description = event.target.value;

		const position = this.props.position;
		position.description = description;
		this.props.onUpdate(position, this.props.pos - 1);
	}

	handleAmountChange(event) {
		let amount = event.target.value;

		const position = this.props.position;
		position.amount = amount;
		this.props.onUpdate(position, this.props.pos - 1);
	}

	handleDiscountChange(event) {
		let discount = event.target.value;

		const position = this.props.position;
		position.discount = discount;
		this.props.onUpdate(position, this.props.pos - 1);
	}

	handlePriceChange(event) {
		let price = event.target.value;

		const position = this.props.position;
		position.price = price;
		this.props.onUpdate(position, this.props.pos - 1);
	}

	handleOnBlur(event) {
		const targetId = event.target.id;

		const key = targetId.split("-")[1];

		const input = document.getElementById(targetId);

		const value = input.value.replace(",", ".");
		const formattedValue = numToDisplay(value);

		const position = this.props.position;
		position[key] = formattedValue;
		this.props.onUpdate(position, this.props.pos - 1);
	}

	render() {
		const position = this.props.position;

		let total = position.total ? position.total : 0;
		total = numToDisplay(total);

		return (
			<div
				id={`invoice-position-${this.props.pos}`}
				className="InvoicePosition"
			>
				<p>{this.props.pos}</p>
				<input
					id={`position-description-${this.props.pos}`}
					value={position.description}
					onChange={this.handleDescriptionChange}
				/>
				<input
					id={`position-amount-${this.props.pos}`}
					value={position.amount}
					onChange={this.handleAmountChange}
					onBlur={this.handleOnBlur}
				/>
				<input
					id={`position-price-${this.props.pos}`}
					value={position.price}
					onChange={this.handlePriceChange}
					onBlur={this.handleOnBlur}
				/>
				<input value={position.discount} onChange={this.handleDiscountChange} />
				<p id={`total-price-of-position-${this.props.pos}`}>{total}</p>
				<IconButton
					sx={{ minWidth: "25px", minHeight: "25px", width: "25px", margin: 0 }}
					onClick={this.addPosition}
					color={"primary"}
				>
					<AddIcon />
				</IconButton>
				<IconButton
					sx={{ minWidth: "25px", minHeight: "25px", width: "25px", margin: 0 }}
					color={"error"}
					onClick={this.removePosition}
				>
					<DeleteIcon />
				</IconButton>
			</div>
		);
	}
}

export default InvoicePosition;
