import React, { useState } from "react";

import { observer } from "mobx-react";
import CustomButton from "../../../../components/CustomButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Field } from "react-final-form";
import CustomInputAdapter from "../../../../components/CustomInputAdapter";

const CarModal = observer(({ initialValues, onSubmit, title, trigger }) => {
	const [open, setOpen] = useState(false);

	const handleClose = () => setOpen(false);

	const submit = (values) => {
		const {
			id,
			manufacturer,
			licensePlate,
			admissionDate,
			chassisNo,
			model,
			typeKey,
			capacity,
			kW,
		} = values;
		onSubmit({
			id,
			manufacturer,
			licensePlate,
			admissionDate,
			chassisNo,
			model,
			typeKey,
			capacity,
			kW,
		});
		handleClose();
	};

	return (
		<>
			{trigger(() => setOpen(true))}
			{open && (
				<Dialog open={open} onClose={handleClose} fullWidth maxWidth={"xs"}>
					<Form
						onSubmit={submit}
						initialValues={
							initialValues
								? initialValues
								: {
										id: "",
										manufacturer: "",
										licensePlate: "",
										admissionDate: "",
										chassisNo: "",
										model: "",
										typeKey: "",
										capacity: "",
										kW: "",
								  }
						}
						render={({ handleSubmit, form, submitting, pristine }) => (
							<form onSubmit={handleSubmit}>
								<DialogTitle>{title}</DialogTitle>
								<DialogContent>
									<Field
										id="manufacturer"
										name="manufacturer"
										label="Hersteller"
										type="manufacturer"
										component={CustomInputAdapter}
										fullWidth
										variant="outlined"
										margin="dense"
									/>
									<Field
										id="model"
										name="model"
										label="Model"
										type="model"
										component={CustomInputAdapter}
										fullWidth
										variant="outlined"
										margin="dense"
									/>
									<Field
										id="licensePlate"
										name="licensePlate"
										label="Kennzeichen"
										type="licensePlate"
										component={CustomInputAdapter}
										fullWidth
										variant="outlined"
										margin="dense"
									/>
									<Field
										id="admissionDate"
										name="admissionDate"
										label="Zulassungsdatum"
										type="admissionDate"
										component={CustomInputAdapter}
										fullWidth
										variant="outlined"
										margin="dense"
									/>
									<Field
										id="chassisNo"
										name="chassisNo"
										label="Fahrg.Nummer"
										type="chassisNo"
										component={CustomInputAdapter}
										fullWidth
										variant="outlined"
										margin="dense"
									/>
									<Field
										id="typeKey"
										name="typeKey"
										label="Typ-Schl"
										type="typeKey"
										component={CustomInputAdapter}
										fullWidth
										variant="outlined"
										margin="dense"
									/>
									<Field
										id="capacity"
										name="capacity"
										label="Hubraum"
										type="capacity"
										component={CustomInputAdapter}
										fullWidth
										variant="outlined"
										margin="dense"
									/>
									<Field
										id="kW"
										name="kW"
										label="kW"
										type="kW"
										component={CustomInputAdapter}
										fullWidth
										variant="outlined"
										margin="dense"
									/>
								</DialogContent>
								<DialogActions>
									<CustomButton
										onClick={form.reset}
										disabled={submitting || pristine}
									>
										Zurücksetzen
									</CustomButton>
									<CustomButton type={"submit"} disabled={submitting}>
										Speichern
									</CustomButton>
								</DialogActions>
							</form>
						)}
					/>
				</Dialog>
			)}
		</>
	);
});

export default CarModal;
