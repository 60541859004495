import React from "react";

//import component
import Popup from "reactjs-popup";

const PopupComponent = ({ contentStyle, overlayStyle, ...props }) => {
	return (
		<Popup
			contentStyle={{ ...contentStyle, background: "white" }}
			overlayStyle={{ ...overlayStyle, background: "rgba(0,0,0,0.5)" }}
			{...props}
		/>
	);
};

export default PopupComponent;
