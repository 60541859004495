import React, { useContext, useEffect } from "react";
import "./CarSection.css";

import CarList from "./components/CarList/CarList";
import { RootStoreContext } from "../../mobX/RootStore";
import { observer } from "mobx-react";

const CarSection = observer(() => {
	const carStore = useContext(RootStoreContext).carStore;

	useEffect(() => {
		carStore.getCars();
	}, []);

	return (
		<div className="Cars">
			<h1>Fahrzeuge</h1>
			<CarList
				cars={carStore.carsOfSelectedCustomer}
				onUpdateCar={carStore.updateCar}
			/>
		</div>
	);
});

export default CarSection;
