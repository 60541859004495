import { makeAutoObservable, runInAction } from "mobx";
import messages from "../utils/messages";

export class CustomerStore {
	transportLayer;
	rootStore;

	customers = [];
	selectedCustomer = null;
	searchTerm = "";

	isLoading = true;

	constructor(transportLayer, rootStore) {
		makeAutoObservable(this);
		this.transportLayer = transportLayer;
		this.rootStore = rootStore;
	}

	getCustomers() {
		this.isLoading = true;
		this.transportLayer
			.listCustomers()
			.then((fetchedCustomers) => {
				runInAction(() => {
					fetchedCustomers.forEach((customer) => {
						this.updateCustomerFromServer(customer);
					});
					this.isLoading = false;
				});
			})
			.catch(() =>
				this.rootStore.UIStore.setErrorMessage(messages.FETCH_FAILURE),
			);
	}

	updateCustomerFromServer(json) {
		const customer = this.customers.find(
			(customerInStore) => customerInStore.id === json.id,
		);
		if (customer) {
			return customer.updateCustomer(json);
		} else {
			const newCustomer = new Customer(json);
			this.customers.push(newCustomer);
			return newCustomer;
		}
	}

	setSearchTerm = (searchTerm) => {
		this.searchTerm = searchTerm.toLowerCase();
	};

	selectCustomer = (customerNo) => {
		this.selectedCustomer = this.customers.find(
			(customer) => customer.customerNo === customerNo,
		);
	};

	updateCustomer = async (customerData) => {
		try {
			const result = await this.transportLayer.updateCustomer(customerData);
			if (result.data.updateCustomer) {
				const updatedCustomerData = result.data.updateCustomer;
				const updatedCustomer =
					this.updateCustomerFromServer(updatedCustomerData);
				this.rootStore.UIStore.setSuccessMessage(messages.UPDATE_SUCCESS);
				return updatedCustomer;
			} else if (result.errors) {
				this.rootStore.UIStore.setErrorMessage(messages.UPDATE_FAILURE);
			}
		} catch (error) {
			console.log("ERROR:", error);
			this.rootStore.UIStore.setErrorMessage(messages.UPDATE_FAILURE);
		}
	};

	createCustomer = async (customerData) => {
		try {
			// customerNo is set in client NOT OPTIMAL BUT IT WORKS
			const customerNo = this.maxCustomerNo + 1;
			const result = await this.transportLayer.createCustomer({
				...customerData,
				customerNo,
			});
			if (result.data.createCustomer) {
				const createdCustomerData = result.data.createCustomer;
				const createdCustomer =
					this.updateCustomerFromServer(createdCustomerData);
				this.rootStore.UIStore.setSuccessMessage(
					messages.CREATE_CUSTOMER_SUCCESS,
				);
				return createdCustomer;
			} else if (result.errors) {
				this.rootStore.UIStore.setErrorMessage(
					messages.CREATE_CUSTOMER_FAILURE,
				);
			}
		} catch (error) {
			console.log("ERROR:", error);
			this.rootStore.UIStore.setErrorMessage(messages.CREATE_CUSTOMER_FAILURE);
		}
	};

	get sortedCustomers() {
		return this.customers.slice().sort((a, b) => {
			if (a.name && b.name) return a.name.localeCompare(b.name);
			if (a.name) return -1;
			if (b.name) return 1;
			return 0;
		});
	}

	get filteredCustomers() {
		const carsWithMatchingLicensePlate = this.rootStore.carStore.cars.filter(
			(car) =>
				car.licensePlate
					? car.licensePlate.toLowerCase().includes(this.searchTerm)
					: false,
		);

		return this.sortedCustomers.filter((customer) => {
			const isMatchingStreet = customer.street
				? customer.street.toLowerCase().includes(this.searchTerm)
				: false;

			const isMatchingName = customer.name
				? customer.name.toLowerCase().includes(this.searchTerm)
				: false;

			const hasMatchingCar = !!carsWithMatchingLicensePlate.find((car) => {
				return car.customerNo === customer.customerNo;
			});

			return isMatchingName || isMatchingStreet || hasMatchingCar;
		});
	}

	get maxCustomerNo() {
		return this.customers.length > 0
			? Math.max(
					...this.customers
						.filter((customer) => Number(customer.customerNo))
						.map((customer) => Number(customer.customerNo)),
			  )
			: 0;
	}
}

export class Customer {
	id;
	customerNo;
	salutation = null;
	name = null;
	street = null;
	town = null;
	zip = null;
	isActive = false;

	constructor(customer) {
		makeAutoObservable(this);

		this.id = customer.id;
		this.customerNo = customer.customerNo;
		this.updateCustomer(customer);
	}

	updateCustomer(customer) {
		this.name = customer.name;
		this.salutation = customer.salutation;
		this.street = customer.street;
		this.town = customer.town;
		this.zip = customer.zip;
		this.isActive = customer.isActive;
	}
}
