import React, { useState } from "react";

import { observer } from "mobx-react";
import CustomButton from "../../../../components/CustomButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Field } from "react-final-form";
import {
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
} from "@mui/material";
import { getSalutationToDisplay } from "../../../../utils/utils";
import CustomInputAdapter from "../../../../components/CustomInputAdapter";

const CustomRadioGroup = ({
	label,
	values,
	input,
	inputProps,
	meta,
	...rest
}) => (
	<FormControl fullWidth error={meta.touched && !!meta.error}>
		<FormLabel>{label}</FormLabel>
		<RadioGroup
			name="radio-buttons-group"
			defaultValue={values[0]}
			{...input}
			{...inputProps}
			{...rest}
		>
			{values.map((value) => (
				<FormControlLabel
					key={value}
					value={value}
					control={<Radio />}
					label={getSalutationToDisplay(value)}
				/>
			))}
			<FormHelperText>{meta.error || " "}</FormHelperText>
		</RadioGroup>
	</FormControl>
);

const CustomerModal = observer(
	({ initialValues, onSubmit, title, trigger }) => {
		const [open, setOpen] = useState(false);

		const handleClose = () => setOpen(false);

		const submit = (values) => {
			const { id, name, salutation, street, zip, town } = values;
			onSubmit({ id, name, salutation, street, zip, town });
			handleClose();
		};

		return (
			<>
				{trigger(() => setOpen(true))}
				{open && (
					<Dialog open={open} onClose={handleClose} fullWidth maxWidth={"xs"}>
						<Form
							onSubmit={submit}
							initialValues={
								initialValues
									? initialValues
									: {
											name: "",
											street: "",
											zip: "",
											town: "",
									  }
							}
							render={({ handleSubmit, form, submitting, pristine }) => (
								<form onSubmit={handleSubmit}>
									<DialogTitle>{title}</DialogTitle>
									<DialogContent>
										<Field
											id="salutation"
											name="salutation"
											label="Anrede"
											type="salutation"
											validate={(value) => !value && "Eingabe erforderlich"}
											component={CustomRadioGroup}
											values={["HERR", "FRAU", "FIRMA"]}
										/>
										<Field
											id="name"
											name="name"
											label="Name"
											type="name"
											validate={(value) => !value && "Eingabe erforderlich"}
											component={CustomInputAdapter}
											fullWidth
											variant="outlined"
											margin="dense"
										/>
										<Field
											id="street"
											name="street"
											label="Straße"
											type="street"
											component={CustomInputAdapter}
											fullWidth
											variant="outlined"
											margin="dense"
										/>
										<Field
											id="zip"
											name="zip"
											label="Postleitzahl"
											type="zip"
											component={CustomInputAdapter}
											fullWidth
											variant="outlined"
											margin="dense"
										/>
										<Field
											id="town"
											name="town"
											label="Ort"
											type="town"
											component={CustomInputAdapter}
											fullWidth
											variant="outlined"
											margin="dense"
										/>
									</DialogContent>
									<DialogActions>
										<CustomButton
											onClick={form.reset}
											disabled={submitting || pristine}
										>
											Zurücksetzen
										</CustomButton>
										<CustomButton type={"submit"} disabled={submitting}>
											Speichern
										</CustomButton>
									</DialogActions>
								</form>
							)}
						/>
					</Dialog>
				)}
			</>
		);
	},
);

export default CustomerModal;
