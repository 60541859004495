import { makeAutoObservable, runInAction } from "mobx";
import messages from "../utils/messages";

export class CarStore {
	transportLayer;
	rootStore;

	cars = [];

	isLoading = true;

	constructor(transportLayer, rootStore) {
		makeAutoObservable(this);
		this.transportLayer = transportLayer;
		this.rootStore = rootStore;
	}

	getCars() {
		this.isLoading = true;
		this.transportLayer
			.listCars()
			.then((fetchedCars) => {
				runInAction(() => {
					fetchedCars.forEach((car) => {
						this.updateCarFromServer(car);
					});
					this.isLoading = false;
				});
			})
			.catch(() =>
				this.rootStore.UIStore.setErrorMessage(messages.FETCH_FAILURE),
			);
	}

	updateCarFromServer(json) {
		const car = this.cars.find((carInStore) => carInStore.id === json.id);
		if (car) {
			return car.updateCar(json);
		} else {
			const newCar = new Car(json);
			this.cars.push(newCar);
			return newCar;
		}
	}

	getCarOfInvoice(invoice) {
		return this.cars.find((car) => car.id === invoice.carID);
	}

	get carsOfSelectedCustomer() {
		return this.rootStore.customerStore.selectedCustomer
			? this.cars.filter(
					(car) =>
						car.customerNo ===
						this.rootStore.customerStore.selectedCustomer.customerNo,
			  )
			: [];
	}

	updateCar = async (carData) => {
		try {
			const result = await this.transportLayer.updateCar(carData);
			if (result.data.updateCar) {
				const updatedCarData = result.data.updateCar;
				const updatedCar = this.updateCarFromServer(updatedCarData);
				this.rootStore.UIStore.setSuccessMessage(messages.UPDATE_SUCCESS);
				return updatedCar;
			} else if (result.errors) {
				this.rootStore.UIStore.setErrorMessage(messages.UPDATE_FAILURE);
			}
		} catch (error) {
			console.log("ERROR:", error);
			this.rootStore.UIStore.setErrorMessage(messages.UPDATE_FAILURE);
		}
	};

	createCar = async (carData) => {
		try {
			const result = await this.transportLayer.createCar({
				...carData,
				isActive: true,
			});
			if (result.data.createCar) {
				const createdCar = result.data.createCar;
				return this.updateCarFromServer(createdCar);
			}
		} catch (error) {
			console.log("ERROR:", error);
		}
	};
}

export class Car {
	id;
	customerNo;
	manufacturer = null;
	licensePlate = null;
	admissionDate = null;
	chassisNo = null;
	model = null;
	typeKey = null;
	capacity = null;
	kW = null;
	isActive = null;

	constructor(car) {
		makeAutoObservable(this);

		this.id = car.id;
		this.customerNo = car.customerNo;
		this.updateCar(car);
	}

	updateCar(car) {
		this.manufacturer = car.manufacturer;
		this.licensePlate = car.licensePlate;
		this.admissionDate = car.admissionDate;
		this.chassisNo = car.chassisNo;
		this.model = car.model;
		this.typeKey = car.typeKey;
		this.capacity = car.capacity;
		this.kW = car.kW;
		this.isActive = car.isActive;

		return this;
	}
}
