import React from "react";
import { Button } from "@mui/material";

// eslint-disable-next-line react/display-name
const CustomButton = React.forwardRef(({ children, sx, ...rest }, ref) => {
	return (
		<Button
			ref={ref}
			variant={"contained"}
			sx={{
				margin: 1,
				...sx,
			}}
			{...rest}
		>
			{children}
		</Button>
	);
});

export default CustomButton;
