import React from "react";
import "./Wage.css";
import { calculateTotalWage, numToDisplay } from "../../../../../utils/utils";
import { observer } from "mobx-react";

class Wage extends React.Component {
	constructor(props) {
		super(props);

		const wage = { ...this.props.wage };

		this.state = {
			wage1: wage.wage1,
			amount1: wage.amount1 || 0,
			price1: wage.price1 || 0,
			wage2: wage.wage2,
			amount2: wage.amount2 || 0,
			price2: wage.price2 || 0,
		};
		this.handleWage1Change = this.handleWage1Change.bind(this);
		this.handleWage2Change = this.handleWage2Change.bind(this);

		this.handleChange = this.handleChange.bind(this);
		this.handleOnBlur = this.handleOnBlur.bind(this);
	}

	handleWage1Change(event) {
		const wage1 = event.target.value;
		this.setState({ wage1: wage1 }, () => this.props.onUpdate(this.state));
	}

	handleWage2Change(event) {
		const wage2 = event.target.value;
		this.setState({ wage2: wage2 }, () => this.props.onUpdate(this.state));
	}

	handleChange(event) {
		const target = event.target.id;
		let value = event.target.value;

		//change , to . (for calculation)
		value = Number(value.replace(",", "."));

		this.setState({ [target]: value }, () => {
			this.props.onUpdate(this.state);
		});
	}

	handleOnBlur(event) {
		const targetId = event.target.id;

		const input = document.getElementById(targetId);

		if (input.value.indexOf(",") === -1) {
			input.value = numToDisplay(input.value);
		}
	}

	render() {
		const wage = this.state;

		let totalWage = calculateTotalWage(wage);
		totalWage = numToDisplay(totalWage);

		return (
			<div className="Wage">
				<h3 id="wage">Lohn</h3>
				<div className="WageProperties">
					<h4>Beschreibung</h4>
					<h4 id="wage-amount">Menge</h4>
					<h4 id="wage-price">Einzelpreis</h4>
				</div>
				<div className="WageInput">
					<input
						id="wage1"
						defaultValue={wage.wage1}
						onChange={this.handleWage1Change}
					/>
					<input
						id="amount1"
						defaultValue={numToDisplay(wage.amount1)}
						onChange={this.handleChange}
						onBlur={this.handleOnBlur}
					/>
					<input
						id="price1"
						defaultValue={numToDisplay(wage.price1)}
						onChange={this.handleChange}
						onBlur={this.handleOnBlur}
					/>
					<input
						id="wage2"
						defaultValue={wage.wage2}
						onChange={this.handleWage2Change}
					/>
					<input
						id="amount2"
						defaultValue={numToDisplay(wage.amount2)}
						onChange={this.handleChange}
						onBlur={this.handleOnBlur}
					/>
					<input
						id="price2"
						defaultValue={numToDisplay(wage.price2)}
						onChange={this.handleChange}
						onBlur={this.handleOnBlur}
					/>
				</div>
				<div className="TotalWage">
					<h4 id="totalWage">Gesamtlohn:</h4>
					<p id="displayTotalWage">{totalWage} €</p>
				</div>
			</div>
		);
	}
}

export default observer(Wage);
