export default {
	CREATE_CUSTOMER_SUCCESS: "Kunde erfolgreich erstellt!",
	CREATE_CUSTOMER_FAILURE: "Kunde konnte nicht erstellt werden!",
	CREATE_INVOICE_SUCCESS: "Rechnung erfolgreich erstellt!",
	CREATE_INVOICE_FAILURE: "Rechnung konnte nicht erstellt werden!",
	UPDATE_SUCCESS: "Änderungen erfolgreich gespeichert!",
	UPDATE_FAILURE: "Änderungen konnten nicht gespeichert werden!",
	DELETE_SUCCESS: "Daten erfolgreich gelöscht!",
	DELETE_FAILURE: "Daten konnten nicht gelöscht werden!",
	FETCH_FAILURE: "Daten konnten nicht geladen werden!",
};
