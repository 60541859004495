import React from "react";
import { FormControl, FormHelperText } from "@mui/material";
import TextField from "@mui/material/TextField";

const CustomInputAdapter = ({ input, meta, inputProps, ...rest }) => (
	<FormControl fullWidth error={meta.touched && !!meta.error}>
		<TextField
			label={
				meta.error && meta.touched
					? `${rest.label} (${meta.error})`
					: rest.label
			}
			inputProps={{ ...inputProps, ...input }}
			{...rest}
			error={meta.touched && !!meta.error}
		/>
		<FormHelperText>{meta.error || " "}</FormHelperText>
	</FormControl>
);

export default CustomInputAdapter;
