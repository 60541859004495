import React from "react";

import { CustomerStore } from "./CustomerStore";
import { InvoiceStore } from "./InvoiceStore";
import { CarStore } from "./CarStore";
import { UIStore } from "./UIStore";

export default class RootStore {
	customerStore;
	invoiceStore;
	carStore;
	UIStore;
	transportLayer;

	constructor(transportLayer) {
		this.transportLayer = transportLayer;
		this.UIStore = new UIStore();
		this.customerStore = new CustomerStore(transportLayer, this);
		this.invoiceStore = new InvoiceStore(transportLayer, this);
		this.carStore = new CarStore(transportLayer, this);
	}
}

const RootStoreContext = React.createContext({});
export { RootStoreContext };
