import React from "react";
import { observer } from "mobx-react";
import "./styles.css";
import CustomerModal from "../CustomerModal";
import CustomButton from "../../../../components/CustomButton";
import { getSalutationToDisplay } from "../../../../utils/utils";

const CustomerListItem = observer((props) => {
	const handleCustomerSelection = () => {
		props.onSelectCustomer(props.customer.customerNo);
	};

	const selectedCustomerNo = props.selectedCustomer
		? props.selectedCustomer.customerNo
		: null;

	return (
		<div
			id={`customer-${props.customer.customerNo}`}
			className="Customer"
			onClick={() => {
				handleCustomerSelection();
			}}
		>
			<div className="Customer-name">
				<h5 style={{ maxWidth: "60%" }}>{props.customer.name}</h5>
				<CustomerModal
					onSubmit={props.onUpdateCustomer}
					title={"Kunden bearbeiten"}
					initialValues={props.customer}
					trigger={(setOpen) => (
						<CustomButton
							size={"small"}
							sx={{ marginLeft: "auto", marginTop: 0, marginBottom: 0 }}
							onClick={setOpen}
						>
							bearbeiten
						</CustomButton>
					)}
				/>
			</div>
			{selectedCustomerNo === props.customer.customerNo && (
				<div className="Customer-information">
					<div>
						<p>Kunden-Nr:</p>
						<p>Anrede:</p>
						<p>Straße:</p>
						<p>Ort:</p>
					</div>
					<div>
						<p>{props.customer.customerNo}&nbsp;</p>
						<p>{getSalutationToDisplay(props.customer.salutation)}&nbsp;</p>
						<p>{props.customer.street}&nbsp;</p>
						<p>
							{props.customer.zip} {props.customer.town}&nbsp;
						</p>
					</div>
				</div>
			)}
		</div>
	);
});

export default CustomerListItem;
