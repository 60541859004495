export const getInvoiceStatus = (status) => {
	switch (status) {
		case "DRAFT":
			return "Entwurf";
		case "PRINTED":
			return "Gedruckt";
		case "CANCELLED":
			return "Storniert";
		default:
			return "error";
	}
};

export const getSalutationToDisplay = (salutation) => {
	switch (salutation) {
		case "HERR":
			return "Herr";
		case "FRAU":
			return "Frau";
		case "FIRMA":
			return "Firma";
	}
};

export const getInvoiceDate = (date) => {
	const splitDate = date.split("-");
	return `${splitDate[2]}.${splitDate[1]}.${splitDate[0]}`;
};

export const asNumber = (number) => {
	return Number(number.replace(",", "."));
};

export const numToDisplay = (number) => {
	if (!number) return "0,00";
	const asNum = typeof number === "string" ? asNumber(number) : number;
	return (Math.round(asNum * 100) / 100)
		.toFixed(2)
		.toString()
		.replace(".", ",");
};

export const getPositionForClient = (position) => ({
	description: position.description,
	amount: numToDisplay(position.amount),
	price: numToDisplay(position.price),
	discount: position.discount ? position.discount.toString() : "0",
	total: position.total,
});

export const getPositionForServer = (position) => ({
	description: position.description,
	amount: asNumber(position.amount),
	price: asNumber(position.price),
	discount: asNumber(position.discount),
	total: position.total,
});

export const calculateTotalWage = (wage) => {
	const amount1 = Number(wage.amount1);
	const price1 = Number(wage.price1);
	const amount2 = Number(wage.amount2);
	const price2 = Number(wage.price2);

	const totalWage = amount1 * price1 + amount2 * price2;
	return roundTwoDec(totalWage);
};

export const getObjectWithoutNullValues = (obj) =>
	Object.fromEntries(Object.entries(obj).filter(([, v]) => v != null));

export const roundTwoDec = (num) => {
	return Math.round(num * 100) / 100;
};

export const getProperties = () => {
	const properties = require("../env/development.json");
	if (process.env.REACT_APP_ENV === "production") {
		return properties.production;
	} else {
		return properties.development;
	}
};

export const isValidZip = (zip) => {
	return zip.match(/[0-9]{5}/);
};

export const falsyValueAsEmptyString = (value) => value || "";
