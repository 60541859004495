import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import "./CustomerSection.css";

import CustomerList from "./components/CustomerList";
import CustomerModal from "./components/CustomerModal";
import { RootStoreContext } from "../../mobX/RootStore";
import CustomButton from "../../components/CustomButton";

const CustomerSection = observer(() => {
	const customerStore = useContext(RootStoreContext).customerStore;

	useEffect(() => {
		customerStore.getCustomers();
	}, [customerStore]);

	const handleTermChange = (event) => {
		let searchTerm = event.target.value;
		customerStore.setSearchTerm(searchTerm);
	};

	return (
		<div className="Customers">
			<div>
				<h1>Kunden</h1>
			</div>
			<div>
				<input
					id="SearchCustomer"
					placeholder="suchen"
					onChange={handleTermChange}
				/>
			</div>
			<CustomerList
				customers={customerStore.filteredCustomers}
				selectedCustomer={customerStore.selectedCustomer}
				onUpdateCustomer={customerStore.updateCustomer}
				onSelectCustomer={customerStore.selectCustomer}
			/>
			<div className="newCustomer">
				<CustomerModal
					onSubmit={customerStore.createCustomer}
					title={"Kunden erstellen"}
					trigger={(setOpen) => (
						<CustomButton onClick={setOpen}>Kunden erstellen</CustomButton>
					)}
				/>
			</div>
		</div>
	);
});

export default CustomerSection;
