import React from "react";
import "./InvoiceInformation.css";

import createPdf from "../../../../utils/pdfDoc";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { numToDisplay } from "../../../../utils/utils";
import { observer } from "mobx-react";
import CustomButton from "../../../../components/CustomButton";
import PrintIcon from "@mui/icons-material/Print";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const InvoiceInformationModal = observer((props) => {
	const printInvoice = () => {
		const { invoice, car, customer } = props;
		const carData = car ? car : {};

		const docDefinition = createPdf(invoice, carData, customer);
		pdfMake.createPdf(docDefinition).print();

		const isDraft = invoice.status === "DRAFT";

		if (isDraft) {
			const newInvoiceStatus = "PRINTED";

			props.onUpdateInvoiceStatus(invoice, newInvoiceStatus);
		}
	};

	const cancelInvoice = () => {
		const { invoice } = props;

		const newInvoiceStatus = "CANCELLED";

		props.onUpdateInvoiceStatus(invoice, newInvoiceStatus);
	};

	const getTotalWageForDisplay = (wage1, wage2) => {
		const total =
			wage1.amount * wage1.pricePerUnit + wage2.amount * wage2.pricePerUnit;
		return numToDisplay(total);
	};

	const { invoice } = props;

	const car = props.car ? props.car : {};

	let totalNetto = invoice.totalNetto ? invoice.totalNetto : 0;
	let taxes = totalNetto * (invoice.VAT / 100);
	let totalBrutto = (totalNetto * (100 + invoice.VAT)) / 100;

	totalNetto = numToDisplay(totalNetto);
	taxes = numToDisplay(taxes);
	totalBrutto = numToDisplay(totalBrutto);

	const isNotCancelled = invoice.status !== "CANCELLED";

	const receptionDay = invoice.receptionDay
		? invoice.receptionDay.split(" ")[0]
		: "";
	const admissionDate = car.admissionDate
		? car.admissionDate.split(" ")[0]
		: "";

	return (
		<div id="invoiceToPrint" className="InvoiceInformation">
			<h2 id="invoice-information-title">Rechnung: {invoice.invoiceNo}</h2>
			<div className="InvoiceInformationCar">
				<h3>Fahrzeug</h3>
				<div className="CarPropertiesWrapper">
					<div>
						<h4>Hersteller</h4>
						<p>{car.manufacturer}</p>
						<h4>Annahme-Tag</h4>
						<p>{receptionDay}</p>
					</div>
					<div>
						<h4>Modell</h4>
						<p>{car.model}</p>
						<h4>Zulassungsdatum</h4>
						<p>{admissionDate}</p>
					</div>
					<div>
						<h4>Kennzeichen</h4>
						<p>{car.licensePlate}</p>
						<h4>Km-Stand</h4>
						<p>{invoice.kmStatus}</p>
					</div>
					<div>
						<h4>Fahrgestell-Nr.</h4>
						<p>{car.chassisNo}</p>
						<h4>TÜV</h4>
						<p>{invoice.tuev}</p>
					</div>
					<div>
						<h4>Typ-Schl.</h4>
						<p>{car.typeKey}</p>
					</div>
				</div>
			</div>
			<div className="InvoicePositionsWrapper">
				<h3 id="Rechnungspositionen">Rechnungspositionen</h3>
				<div className="PositionProperties">
					<h4>Pos.</h4>
					<h4>Beschreibung</h4>
					<h4 id="amount">Menge</h4>
					<h4 id="price">Einzelpreis</h4>
					<h4 id="discount">Rabatt(%)</h4>
					<h4 id="total">Gesamt</h4>
				</div>
				<div className="InvoiceInformationPositions">
					{invoice.positions.map((position, index) => {
						return (
							<div className="PositionInformation" key={index}>
								<p>{index + 1}</p>
								<p>{position.description}</p>
								<p id="amount">{position.amount}</p>
								<p id="price">{position.price} €</p>
								<p id="discount">{position.discount}</p>
								<p id="total">{position.total} €</p>
							</div>
						);
					})}
				</div>
			</div>
			<div className="SalaryInvoiceInformationTotalWrapper">
				<div className="Wage">
					<h3>Lohn</h3>
					<div className="WageInformation">
						<h4>Beschreibung</h4>
						<h4 id="amount">Menge</h4>
						<h4 id="price">Einzelpreis</h4>
						<p>{invoice.wage1.name}</p>
						<p id="amount">{numToDisplay(invoice.wage1.amount)}</p>
						<p id="price">{numToDisplay(invoice.wage1.pricePerUnit)} €</p>
						<p>{invoice.wage2.name}</p>
						<p id="amount">{numToDisplay(invoice.wage2.amount)}</p>
						<p id="price">{numToDisplay(invoice.wage2.pricePerUnit)} €</p>
						<p>
							Gesamtlohn: {getTotalWageForDisplay(invoice.wage1, invoice.wage2)}{" "}
							€
						</p>
					</div>
				</div>
				<div className="invoice-information-total">
					<h3>Gesamt</h3>
					<div className="InvoiceInformationTotalAttributes">
						<div>
							<p>Betrag Netto: </p>
							<p>MwSt. {invoice.VAT}%: </p>
							<p>Betrag Brutto: </p>
						</div>
						<div>
							<p>{totalNetto} €</p>
							<p>{taxes} €</p>
							<p>{totalBrutto} €</p>
						</div>
					</div>
				</div>
			</div>
			<div className="bottom">
				<CustomButton onClick={props.close}>schließen</CustomButton>
				{isNotCancelled && (
					<CustomButton
						startIcon={<PrintIcon />}
						onClick={() => {
							printInvoice();
							props.close();
						}}
					>
						drucken
					</CustomButton>
				)}
				{isNotCancelled && (
					<CustomButton
						onClick={() => {
							cancelInvoice();
							props.close();
						}}
					>
						stornieren
					</CustomButton>
				)}
			</div>
		</div>
	);
});

export default InvoiceInformationModal;
