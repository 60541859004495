import React from "react";
import "./CarButton.css";

class CarButton extends React.Component {
	constructor(props) {
		super(props);

		this.handleSelection = this.handleSelection.bind(this);
	}

	handleSelection() {
		this.props.onSelectCar(this.props.car);
	}

	render() {
		return (
			<div className="CarButton">
				<button onClick={this.handleSelection}>
					{this.props.car.licensePlate}
				</button>
			</div>
		);
	}
}

export default CarButton;
