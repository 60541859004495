export function awsConfig() {
	if (process.env.NODE_ENV === "production") {
		return {
			Auth: {
				region: "eu-central-1",
				userPoolId: "eu-central-1_5JRReGHRT",
				userPoolWebClientId: "tmuebnigncjit42jtmhlh4i86",
			},
			aws_appsync_graphqlEndpoint:
				"https://z3y34belhveuvahngwomchwej4.appsync-api.eu-central-1.amazonaws.com/graphql",
			aws_appsync_region: "eu-central-1",
			aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
		};
	} else {
		return {
			Auth: {
				region: "eu-central-1",
				userPoolId: "eu-central-1_zpZdfOWN1",
				userPoolWebClientId: "10kj4c6o7r9lvtai0gd4pnb31u",
			},
			aws_appsync_graphqlEndpoint:
				"https://gshuheqhy5hhbfjiuqxndvdsja.appsync-api.eu-central-1.amazonaws.com/graphql",
			aws_appsync_region: "eu-central-1",
			aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
		};
	}
}
