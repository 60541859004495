import React from "react";
import "./CustomerList.css";

//import components
import CustomerListItem from "../CustomerListItem";
import { observer } from "mobx-react";

const CustomerList = observer((props) => {
	return (
		<div className="CustomerList">
			{props.customers.length > 0
				? props.customers.map((customer) => {
						return (
							<CustomerListItem
								customer={customer}
								key={customer.customerNo}
								selectedCustomer={props.selectedCustomer}
								onSelectCustomer={props.onSelectCustomer}
								onUpdateCustomer={props.onUpdateCustomer}
								onDeleteCustomer={props.onDeleteCustomer}
							/>
						);
				  })
				: null}
		</div>
	);
});

export default CustomerList;
