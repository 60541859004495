import { API } from "aws-amplify";
import * as queries from "./currentOperations/queries";
import * as mutations from "./currentOperations/mutations";
import { getObjectWithoutNullValues } from "../utils/utils";

export class GraphqlApi {
	listCustomers = async (customers, nextToken = null) => {
		const params = {
			query: queries.listCustomers,
			variables: {
				nextToken,
			},
		};

		const result = await API.graphql(params);
		const fetchedCustomers = result.data.listCustomers.items;
		if (customers) fetchedCustomers.push(...customers);
		const token = result.data.listCustomers.nextToken;
		if (token) {
			return this.listCustomers(fetchedCustomers, token);
		}
		return fetchedCustomers;
	};

	listInvoices = async (invoices, nextToken = null) => {
		const params = {
			query: queries.listInvoices,
			variables: {
				nextToken,
			},
		};

		const result = await API.graphql(params);
		const fetchedInvoices = result.data.listInvoices.items;
		if (invoices) fetchedInvoices.push(...invoices);
		const token = result.data.listInvoices.nextToken;
		if (token) {
			return this.listInvoices(fetchedInvoices, token);
		}
		return fetchedInvoices;
	};

	listInvoicesByCustomer = async (customerNo, invoices, nextToken = null) => {
		const params = {
			query: queries.invoiceByCustomerNo,
			variables: {
				customerNo,
				nextToken,
			},
		};

		const result = await API.graphql(params);
		const fetchedInvoices = result.data.invoiceByCustomerNo.items;
		if (invoices) fetchedInvoices.push(...invoices);
		const token = result.data.invoiceByCustomerNo.nextToken;
		if (token) {
			return this.listInvoicesByCustomer(customerNo, fetchedInvoices, token);
		}
		return fetchedInvoices;
	};

	listCars = async (cars, nextToken = null) => {
		const params = {
			query: queries.listCars,
			variables: {
				nextToken,
			},
		};

		const result = await API.graphql(params);
		const fetchedCars = result.data.listCars.items;
		if (cars) fetchedCars.push(...cars);
		const token = result.data.listCars.nextToken;
		if (token) {
			return this.listCars(fetchedCars, token);
		}
		return fetchedCars;
	};

	getInvoicesByCustomer = async (customerNo, invoices, nextToken = null) => {
		const params = {
			query: queries.invoiceByCustomerNo,
			variables: {
				input: {
					customerNo,
				},
			},
			nextToken,
		};

		const result = await API.graphql(params);
		const fetchedInvoices = result.data.invoiceByCustomerNo.items;
		if (invoices) fetchedInvoices.push(...invoices);
		params.nextToken = result.data.invoiceByCustomerNo.nextToken;
		if (params.nextToken)
			return this.getInvoicesByCustomer(
				customerNo,
				fetchedInvoices,
				params.nextToken,
			);
		return fetchedInvoices;
	};

	updateCustomer = async (customerData) => {
		const params = {
			query: mutations.updateCustomer,
			variables: {
				input: {
					...customerData,
				},
			},
		};

		return API.graphql(params);
	};

	createCustomer = async (customerData) => {
		const params = {
			query: mutations.createCustomer,
			variables: {
				input: {
					...customerData,
				},
			},
		};

		return API.graphql(params);
	};

	updateInvoice = async (invoiceData) => {
		const params = {
			query: mutations.updateInvoice,
			variables: {
				input: {
					...getObjectWithoutNullValues(invoiceData),
				},
			},
		};

		return API.graphql(params);
	};

	createInvoice = async (invoiceData) => {
		const params = {
			query: mutations.createInvoice,
			variables: {
				input: {
					...getObjectWithoutNullValues(invoiceData),
				},
			},
		};

		return API.graphql(params);
	};

	updateCar = async (carData) => {
		const params = {
			query: mutations.updateCar,
			variables: {
				input: {
					...getObjectWithoutNullValues(carData),
				},
			},
		};

		return API.graphql(params);
	};

	createCar = async (carData) => {
		const params = {
			query: mutations.createCar,
			variables: {
				input: {
					...getObjectWithoutNullValues(carData),
				},
			},
		};

		return API.graphql(params);
	};
}
