"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true,
});
exports.listInvoices =
	exports.listCustomers =
	exports.listCars =
	exports.invoiceByCustomerNo =
	exports.getInvoice =
	exports.getCustomer =
	exports.getCar =
	exports.customerByCustomerNo =
	exports.carByCustomerNo =
		void 0;

/* eslint-disable */
// this is an auto generated file. This will be overwritten
const getCustomer =
	/* GraphQL */
	`
		query GetCustomer($id: ID!) {
			getCustomer(id: $id) {
				id
				customerNo
				salutation
				name
				street
				town
				zip
				isActive
				invoices {
					nextToken
				}
				cars {
					nextToken
				}
				createdAt
				updatedAt
			}
		}
	`;
exports.getCustomer = getCustomer;
const listCustomers =
	/* GraphQL */
	`
		query ListCustomers(
			$id: ID
			$filter: ModelCustomerFilterInput
			$limit: Int
			$nextToken: String
			$sortDirection: ModelSortDirection
		) {
			listCustomers(
				id: $id
				filter: $filter
				limit: $limit
				nextToken: $nextToken
				sortDirection: $sortDirection
			) {
				items {
					id
					customerNo
					salutation
					name
					street
					town
					zip
					isActive
					createdAt
					updatedAt
				}
				nextToken
			}
		}
	`;
exports.listCustomers = listCustomers;
const customerByCustomerNo =
	/* GraphQL */
	`
		query CustomerByCustomerNo(
			$customerNo: String!
			$sortDirection: ModelSortDirection
			$filter: ModelCustomerFilterInput
			$limit: Int
			$nextToken: String
		) {
			customerByCustomerNo(
				customerNo: $customerNo
				sortDirection: $sortDirection
				filter: $filter
				limit: $limit
				nextToken: $nextToken
			) {
				items {
					id
					customerNo
					salutation
					name
					street
					town
					zip
					isActive
					createdAt
					updatedAt
				}
				nextToken
			}
		}
	`;
exports.customerByCustomerNo = customerByCustomerNo;
const getInvoice =
	/* GraphQL */
	`
		query GetInvoice($id: ID!) {
			getInvoice(id: $id) {
				id
				invoiceNo
				customerNo
				carID
				invoiceDate
				wage1 {
					name
					amount
					pricePerUnit
				}
				wage2 {
					name
					amount
					pricePerUnit
				}
				totalNetto
				positions {
					description
					amount
					price
					discount
					total
				}
				receptionDay
				kmStatus
				tuev
				exhaustInvestigation
				status
				VAT
				createdAt
				updatedAt
			}
		}
	`;
exports.getInvoice = getInvoice;
const listInvoices =
	/* GraphQL */
	`
		query ListInvoices(
			$id: ID
			$filter: ModelInvoiceFilterInput
			$limit: Int
			$nextToken: String
			$sortDirection: ModelSortDirection
		) {
			listInvoices(
				id: $id
				filter: $filter
				limit: $limit
				nextToken: $nextToken
				sortDirection: $sortDirection
			) {
				items {
					id
					invoiceNo
					customerNo
					carID
					invoiceDate
					totalNetto
					receptionDay
					kmStatus
					tuev
					exhaustInvestigation
					status
					VAT
					wage1 {
						name
						amount
						pricePerUnit
					}
					wage2 {
						name
						amount
						pricePerUnit
					}
					positions {
						description
						amount
						price
						discount
						total
					}
					createdAt
					updatedAt
				}
				nextToken
			}
		}
	`;
exports.listInvoices = listInvoices;
const invoiceByCustomerNo =
	/* GraphQL */
	`
		query InvoiceByCustomerNo(
			$customerNo: String!
			$sortDirection: ModelSortDirection
			$filter: ModelInvoiceFilterInput
			$limit: Int
			$nextToken: String
		) {
			invoiceByCustomerNo(
				customerNo: $customerNo
				sortDirection: $sortDirection
				filter: $filter
				limit: $limit
				nextToken: $nextToken
			) {
				items {
					id
					invoiceNo
					customerNo
					carID
					invoiceDate
					totalNetto
					receptionDay
					kmStatus
					tuev
					exhaustInvestigation
					status
					VAT
					wage1 {
						name
						amount
						pricePerUnit
					}
					wage2 {
						name
						amount
						pricePerUnit
					}
					positions {
						description
						amount
						price
						discount
						total
					}
					createdAt
					updatedAt
				}
				nextToken
			}
		}
	`;
exports.invoiceByCustomerNo = invoiceByCustomerNo;
const getCar =
	/* GraphQL */
	`
		query GetCar($id: ID!) {
			getCar(id: $id) {
				id
				customerNo
				manufacturer
				licensePlate
				admissionDate
				chassisNo
				model
				typeKey
				capacity
				kW
				isActive
				createdAt
				updatedAt
			}
		}
	`;
exports.getCar = getCar;
const listCars =
	/* GraphQL */
	`
		query ListCars(
			$id: ID
			$filter: ModelCarFilterInput
			$limit: Int
			$nextToken: String
			$sortDirection: ModelSortDirection
		) {
			listCars(
				id: $id
				filter: $filter
				limit: $limit
				nextToken: $nextToken
				sortDirection: $sortDirection
			) {
				items {
					id
					customerNo
					manufacturer
					licensePlate
					admissionDate
					chassisNo
					model
					typeKey
					capacity
					kW
					isActive
					createdAt
					updatedAt
				}
				nextToken
			}
		}
	`;
exports.listCars = listCars;
const carByCustomerNo =
	/* GraphQL */
	`
		query CarByCustomerNo(
			$customerNo: String!
			$sortDirection: ModelSortDirection
			$filter: ModelCarFilterInput
			$limit: Int
			$nextToken: String
		) {
			carByCustomerNo(
				customerNo: $customerNo
				sortDirection: $sortDirection
				filter: $filter
				limit: $limit
				nextToken: $nextToken
			) {
				items {
					id
					customerNo
					manufacturer
					licensePlate
					admissionDate
					chassisNo
					model
					typeKey
					capacity
					kW
					isActive
					createdAt
					updatedAt
				}
				nextToken
			}
		}
	`;
exports.carByCustomerNo = carByCustomerNo;
