import { makeAutoObservable, runInAction } from "mobx";
import { getPositionForClient, getPositionForServer } from "../utils/utils";
import messages from "../utils/messages";

export class InvoiceStore {
	transportLayer;
	rootStore;

	invoices = [];

	isLoading = true;

	constructor(transportLayer, rootStore) {
		makeAutoObservable(this);
		this.transportLayer = transportLayer;
		this.rootStore = rootStore;
	}

	fetchInvoices() {
		this.isLoading = true;
		this.transportLayer
			.listInvoices()
			.then((fetchedInvoices) => {
				runInAction(() => {
					fetchedInvoices.forEach((invoice) => {
						this.updateInvoiceFromServer(invoice);
					});
					this.isLoading = false;
				});
			})
			.catch((error) => {
				console.error(error);
				this.rootStore.UIStore.setErrorMessage(messages.FETCH_FAILURE);
			});
	}

	fetchInvoicesByCustomer(customerNo) {
		this.isLoading = true;
		this.transportLayer
			.listInvoicesByCustomer(customerNo)
			.then((fetchedInvoices) => {
				runInAction(() => {
					fetchedInvoices.forEach((invoice) => {
						this.updateInvoiceFromServer(invoice);
					});
					this.isLoading = false;
				});
			})
			.catch((error) => {
				console.error(error);
				this.rootStore.UIStore.setErrorMessage(messages.FETCH_FAILURE);
			});
	}

	updateInvoiceFromServer(json) {
		const invoice = this.invoices.find(
			(invoiceInStore) => invoiceInStore.id === json.id,
		);
		if (invoice) {
			return invoice.updateInvoice(json);
		} else {
			const newInvoice = new Invoice(json);
			this.invoices.push(newInvoice);
			return newInvoice;
		}
	}

	updateInvoiceStatus = async (invoice, status) => {
		await this.updateInvoice({ ...invoice, status });
	};

	updateInvoice = async (invoiceData) => {
		const positions = invoiceData.positions?.map((position) =>
			getPositionForServer(position),
		);

		try {
			const result = await this.transportLayer.updateInvoice({
				...invoiceData,
				positions,
			});
			if (result.data.updateInvoice) {
				const updatedInvoiceData = result.data.updateInvoice;
				const updatedInvoice = this.updateInvoiceFromServer(updatedInvoiceData);
				this.rootStore.UIStore.setSuccessMessage(messages.UPDATE_SUCCESS);
				return updatedInvoice;
			} else if (result.errors) {
				this.rootStore.UIStore.setErrorMessage(messages.UPDATE_FAILURE);
			}
		} catch (error) {
			console.log("ERROR:", error);
			this.rootStore.UIStore.setErrorMessage(messages.UPDATE_FAILURE);
		}
	};

	createInvoice = async (invoiceData) => {
		const positions = invoiceData.positions.map((position) =>
			getPositionForServer(position),
		);

		try {
			// customerNo is set in client NOT OPTIMAL BUT IT WORKS
			const invoiceNo = this.maxInvoiceNo + 1;
			const result = await this.transportLayer.createInvoice({
				...invoiceData,
				positions,
				invoiceNo,
			});
			if (result.data.createInvoice) {
				const createdInvoiceData = result.data.createInvoice;
				const createdInvoice = this.updateInvoiceFromServer(createdInvoiceData);
				this.rootStore.UIStore.setSuccessMessage(
					messages.CREATE_INVOICE_SUCCESS,
				);
				return createdInvoice;
			} else if (result.errors) {
				this.rootStore.UIStore.setErrorMessage(messages.CREATE_INVOICE_FAILURE);
			}
		} catch (error) {
			console.log("ERROR:", error);
			this.rootStore.UIStore.setErrorMessage(messages.CREATE_INVOICE_FAILURE);
		}
	};

	get invoicesOfSelectedCustomer() {
		return this.rootStore.customerStore.selectedCustomer
			? this.invoices
					.filter(
						(invoice) =>
							invoice.customerNo ===
							this.rootStore.customerStore.selectedCustomer.customerNo,
					)
					.sort((a, b) => {
						if (a.invoiceNo < b.invoiceNo) {
							return 1;
						} else if (a.invoiceNo > b.invoiceNo) {
							return -1;
						} else {
							return 0;
						}
					})
			: [];
	}

	getInvoicesByCustomer = (customerNo) => {
		return this.invoices.find((invoice) => invoice.customerNo === customerNo);
	};

	get maxInvoiceNo() {
		return this.invoices.length > 0
			? Math.max(...this.invoices.map((invoice) => invoice.invoiceNo))
			: 0;
	}
}

export class Invoice {
	id;
	customerNo;
	invoiceNo;
	carID = null;
	invoiceDate = null;
	wage1 = null;
	wage2 = null;
	totalNetto = null;
	positions = [];
	receptionDay = null;
	kmStatus = null;
	tuev = null;
	exhaustInvestigation = null;
	status = null;
	VAT = null;

	constructor(invoice) {
		makeAutoObservable(this);

		this.id = invoice.id;
		this.invoiceNo = invoice.invoiceNo;
		this.customerNo = invoice.customerNo;
		this.updateInvoice(invoice);
	}

	updateInvoice(invoice) {
		this.carID = invoice.carID;
		this.invoiceDate = invoice.invoiceDate;
		this.wage1 = invoice.wage1;
		this.wage2 = invoice.wage2;
		this.totalNetto = invoice.totalNetto;
		this.positions = invoice.positions
			? invoice.positions.map((position) => getPositionForClient(position))
			: [];
		this.receptionDay = invoice.receptionDay;
		this.kmStatus = invoice.kmStatus;
		this.tuev = invoice.tuev;
		this.exhaustInvestigation = invoice.exhaustInvestigation;
		this.status = invoice.status;
		this.VAT = invoice.VAT;

		return this;
	}
}
