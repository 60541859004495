import React from "react";
import "./InvoiceCar.css";

import CarButton from "../CarButton/CarButton";

class InvoiceCar extends React.Component {
	constructor(props) {
		super(props);

		const car = this.props.car ? { ...this.props.car } : {};
		const invoice = this.props.invoice ? this.props.invoice : {};

		this.state = {
			id: car.id,
			customerNo: car.customerNo,
			manufacturer: car.manufacturer,
			licensePlate: car.licensePlate,
			admissionDate: car.admissionDate,
			chassisNo: car.chassisNo,
			model: car.model,
			typeKey: car.typeKey,
			receptionDay: invoice.receptionDay,
			kmStatus: invoice.kmStatus,
			tuev: invoice.tuev,
		};

		this.selectCar = this.selectCar.bind(this);
		this.handleManufacturerChange = this.handleManufacturerChange.bind(this);
		this.handleModelChange = this.handleModelChange.bind(this);
		this.handleLicensePlateChange = this.handleLicensePlateChange.bind(this);
		this.handleChassisNoChange = this.handleChassisNoChange.bind(this);
		this.handleAdmissionDateChange = this.handleAdmissionDateChange.bind(this);
		this.handleTypeKeyChange = this.handleTypeKeyChange.bind(this);
		this.handleReceptionDayChange = this.handleReceptionDayChange.bind(this);
		this.handleKmStatusChange = this.handleKmStatusChange.bind(this);
		this.handleTuevChange = this.handleTuevChange.bind(this);
	}

	//selects car
	selectCar(car) {
		this.setState(
			{
				id: car.id,
				manufacturer: car.manufacturer,
				licensePlate: car.licensePlate,
				admissionDate: car.admissionDate,
				chassisNo: car.chassisNo,
				model: car.model,
				typeKey: car.typeKey,
				capacity: car.capacity,
				kW: car.kW,
			},
			() => {
				this.props.onUpdateCarProperties(...Object.values(this.state));
			},
		);
	}

	handleManufacturerChange(event) {
		const manufacturer = event.target.value;
		this.setState({ manufacturer: manufacturer }, () => {
			this.props.onUpdateCarProperties(...Object.values(this.state));
		});
	}

	handleModelChange(event) {
		const model = event.target.value;
		this.setState({ model: model }, () => {
			this.props.onUpdateCarProperties(...Object.values(this.state));
		});
	}

	handleLicensePlateChange(event) {
		const licensePlate = event.target.value;
		this.setState({ licensePlate: licensePlate }, () => {
			this.props.onUpdateCarProperties(...Object.values(this.state));
		});
	}

	handleChassisNoChange(event) {
		const chassisNo = event.target.value;
		this.setState({ chassisNo: chassisNo }, () => {
			this.props.onUpdateCarProperties(...Object.values(this.state));
		});
	}

	handleAdmissionDateChange(event) {
		const admissionDate = event.target.value;
		this.setState({ admissionDate: admissionDate }, () => {
			this.props.onUpdateCarProperties(...Object.values(this.state));
		});
	}

	handleTypeKeyChange(event) {
		const typeKey = event.target.value;
		this.setState({ typeKey: typeKey }, () => {
			this.props.onUpdateCarProperties(...Object.values(this.state));
		});
	}

	handleReceptionDayChange(event) {
		const receptionDay = event.target.value;
		this.setState({ receptionDay: receptionDay }, () => {
			this.props.onUpdateCarProperties(...Object.values(this.state));
		});
	}

	handleKmStatusChange(event) {
		const kmStatus = event.target.value;
		this.setState({ kmStatus: kmStatus }, () => {
			this.props.onUpdateCarProperties(...Object.values(this.state));
		});
	}

	handleTuevChange(event) {
		const tuev = event.target.value;
		this.setState({ tuev: tuev }, () => {
			this.props.onUpdateCarProperties(...Object.values(this.state));
		});
	}

	render() {
		//console.log("cars:", this.props.selectedCars);
		return (
			<div className="InvoiceCar">
				<div className="ActiveCarsWrapper">
					<div>
						<h3>Fahrzeug</h3>
					</div>
					<div className="ActiveCars">
						{this.props.selectedCars
							.filter((car) => car.isActive)
							.map((car) => {
								return (
									<CarButton
										car={car}
										onSelectCar={this.selectCar}
										key={car.id}
									/>
								);
							})}
					</div>
				</div>
				<div className="CarPropertiesWrapper">
					<div>
						<h4>Hersteller</h4>
						<input
							value={this.state.manufacturer || ""}
							onChange={this.handleManufacturerChange}
						/>
						<h4>Annahme-Tag</h4>
						<input
							value={this.state.receptionDay || ""}
							onChange={this.handleReceptionDayChange}
						/>
					</div>
					<div>
						<h4>Modell</h4>
						<input
							value={this.state.model || ""}
							onChange={this.handleModelChange}
						/>
						<h4>Zulassungsdatum</h4>
						<input
							value={this.state.admissionDate || ""}
							onChange={this.handleAdmissionDateChange}
						/>
					</div>
					<div>
						<h4>Kennzeichen</h4>
						<input
							value={this.state.licensePlate || ""}
							onChange={this.handleLicensePlateChange}
						/>
						<h4>Km-Stand</h4>
						<input
							value={this.state.kmStatus || ""}
							onChange={this.handleKmStatusChange}
						/>
					</div>
					<div>
						<h4>Fahrgestell-Nr.</h4>
						<input
							value={this.state.chassisNo || ""}
							onChange={this.handleChassisNoChange}
						/>
						<h4>TÜV</h4>
						<input
							value={this.state.tuev || ""}
							onChange={this.handleTuevChange}
						/>
					</div>
					<div>
						<h4>Typ-Schl.</h4>
						<input
							value={this.state.typeKey || ""}
							onChange={this.handleTypeKeyChange}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default InvoiceCar;
