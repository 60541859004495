import React from "react";
import "./CarList.css";
import CarListItem from "../CarListItem/CarListItem";
import { observer } from "mobx-react";

const CarList = observer(({ cars, onUpdateCar }) => {
	return (
		<div className="CarList">
			{cars.map((car) => {
				return <CarListItem car={car} key={car.id} onUpdateCar={onUpdateCar} />;
			})}
		</div>
	);
});

export default CarList;
