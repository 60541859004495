import React, { useContext, useEffect } from "react";
import "./InvoiceSection.css";

import InvoiceList from "./components/InvoiceList/InvoiceList";
import EditableInvoice from "./components/EditableInvoiceModal";
import { RootStoreContext } from "../../mobX/RootStore";
import { observer } from "mobx-react";
import PopupComponent from "../../components/Popup/Popup";
import CustomButton from "../../components/CustomButton";

const InvoiceSection = observer((props) => {
	const rootStore = useContext(RootStoreContext);
	const invoiceStore = rootStore.invoiceStore;
	const customerStore = rootStore.customerStore;
	const carStore = rootStore.carStore;

	const selectedCustomer = customerStore.selectedCustomer;
	const customerNo = selectedCustomer ? selectedCustomer.customerNo : null;

	useEffect(() => {
		invoiceStore.fetchInvoices();
	}, []);
	const isCustomerSelected = !!customerNo;

	return (
		<div className="Invoices">
			<h1>Rechnungen</h1>
			{!isCustomerSelected && (
				<CustomButton variant={"outlined"} disableRipple color="error">
					wähle Kunden
				</CustomButton>
			)}
			{isCustomerSelected && (
				<div className="new">
					<PopupComponent
						contentStyle={{
							width: "1050px",
							height: "740px",
							padding: "none",
							border: "none",
							borderRadius: "10px",
						}}
						closeOnDocumentClick={false}
						modal
						trigger={(open) => {
							return <CustomButton onClick={open}>neue Rechnung</CustomButton>;
						}}
					>
						{(close) => {
							return (
								<EditableInvoice
									close={close}
									selectedCustomer={selectedCustomer}
									selectedCars={carStore.carsOfSelectedCustomer}
									onSubmit={invoiceStore.createInvoice}
									onCreateCar={carStore.createCar}
									onUpdateCar={carStore.updateCar}
									onUpdateInvoiceStatus={invoiceStore.updateInvoiceStatus}
									properties={props.properties}
								/>
							);
						}}
					</PopupComponent>
				</div>
			)}
			<InvoiceList properties={props.properties} />
		</div>
	);
});

export default InvoiceSection;
