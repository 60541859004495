import React, { useContext } from "react";
import "./InvoiceList.css";

//import components
import InvoiceListItem from "../InvoiceListItem/InvoiceListItem";
import { observer } from "mobx-react";
import { RootStoreContext } from "../../../../mobX/RootStore";

const InvoiceList = observer((props) => {
	const rootStore = useContext(RootStoreContext);
	const invoiceStore = rootStore.invoiceStore;

	return (
		<div className="InvoiceList">
			<div className="Properties">
				<h4>Rechnungs-Nr</h4>
				<h4>Status</h4>
				<h4>Datum</h4>
				<h4>Positionen</h4>
				<h4 id="totalPricePropertie">Betrag</h4>
			</div>
			<div className="List">
				{invoiceStore.invoicesOfSelectedCustomer.map((invoice) => {
					return (
						<InvoiceListItem
							invoice={invoice}
							key={invoice.invoiceNo}
							properties={props.properties}
						/>
					);
				})}
			</div>
		</div>
	);
});

export default InvoiceList;
