import React, { useContext } from "react";
import "./InvoiceListItem.css";

import Popup from "../../../../components/Popup/Popup";
import InvoiceInformationModal from "../InvoiceInformationModal";
import EditableInvoice from "../EditableInvoiceModal";
import { numToDisplay } from "../../../../utils/utils";
import { observer } from "mobx-react";
import { getInvoiceDate, getInvoiceStatus } from "../../../../utils/utils";
import { RootStoreContext } from "../../../../mobX/RootStore";

const InvoiceListItem = observer((props) => {
	const rootStore = useContext(RootStoreContext);
	const invoiceStore = rootStore.invoiceStore;
	const customerStore = rootStore.customerStore;
	const carStore = rootStore.carStore;

	const invoice = props.invoice;

	const editableModal = (close) => {
		return (
			<EditableInvoice
				close={close}
				invoice={invoice}
				car={carStore.getCarOfInvoice(invoice)}
				selectedCustomer={customerStore.selectedCustomer}
				selectedCars={carStore.carsOfSelectedCustomer}
				onUpdateCar={carStore.updateCar}
				onCreateCar={carStore.createCar}
				onUpdateInvoiceStatus={invoiceStore.updateInvoiceStatus}
				onSubmit={invoiceStore.updateInvoice}
				properties={props.properties}
			/>
		);
	};

	const informationModal = (close) => {
		return (
			<InvoiceInformationModal
				close={close}
				invoice={invoice}
				car={carStore.getCarOfInvoice(invoice)}
				customer={customerStore.selectedCustomer}
				onUpdateInvoiceStatus={invoiceStore.updateInvoiceStatus}
				onUpdateInvoiceData={invoiceStore.updateInvoice}
			/>
		);
	};
	const invoiceModal =
		invoice.status === "DRAFT" ? editableModal : informationModal;

	let totalBrutto = (invoice.totalNetto * (100 + invoice.VAT)) / 100;
	totalBrutto = numToDisplay(totalBrutto);

	return (
		<div>
			<Popup
				contentStyle={{
					width: "1050px",
					height: "740px",
					padding: "none",
					border: "none",
					borderRadius: "10px",
				}}
				closeOnDocumentClick={false}
				modal
				trigger={
					<div className="Invoice">
						<p>{invoice.invoiceNo}</p>
						<p>{getInvoiceStatus(invoice.status)}</p>
						<p>{getInvoiceDate(invoice.invoiceDate)}</p>
						<p id="number-positions">{invoice.positions.length}</p>
						<p id="totalPrice">{totalBrutto}</p>
					</div>
				}
			>
				{(close) => invoiceModal(close)}
			</Popup>
		</div>
	);
});

export default InvoiceListItem;
