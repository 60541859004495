import React, { useContext, useState } from "react";
import "./App.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";

import InvoiceSection from "../sections/InvoiceSection";
import CustomerSection from "../sections/CustomerSection";
import CarSection from "../sections/CarSection";

import { withAuthenticator } from "@aws-amplify/ui-react";
import { observer } from "mobx-react";
import { getProperties } from "../utils/utils";
import CustomButton from "../components/CustomButton";
import CustomSnackbar from "../components/CustomSnackbar";
import { RootStoreContext } from "../mobX/RootStore";

const App = observer(({ signOut, user }) => {
	const rootStore = useContext(RootStoreContext);
	const UIStore = rootStore.UIStore;
	const [showMenu, toggleMenu] = useState(false);

	const properties = getProperties();

	const toggleUserMenu = () => {
		toggleMenu(!showMenu);
	};

	return (
		<div>
			<CustomSnackbar
				open={!!UIStore.successMessage}
				severity={"success"}
				message={UIStore.successMessage}
				onClose={UIStore.resetSuccessMessage}
			/>
			<CustomSnackbar
				open={!!UIStore.errorMessage}
				severity={"error"}
				message={UIStore.errorMessage}
				onClose={UIStore.resetErrorMessage}
			/>

			<div className="App">
				<div className="header">
					<h2 id="title">ASB Invoice</h2>
					<div className="loggedIn" onClick={toggleUserMenu}>
						<div className="user-icon">
							<FontAwesomeIcon id="user-circle" icon={faUserCircle} size="2x" />
							<p id="user-name">{user.attributes.email}</p>
							<CustomButton color={"error"} onClick={signOut}>
								Abmelden
							</CustomButton>
						</div>
					</div>
				</div>
				<div className="Content">
					<CustomerSection />
					<InvoiceSection properties={properties} />
					<CarSection />
				</div>
			</div>
		</div>
	);
});

export default withAuthenticator(
	({ signOut, user }) => <App signOut={signOut} user={user} />,
	{ hideSignUp: true },
);
